export enum FlashMessages {
  AccountDeleted = 'Votre compte a été supprimé avec succès',
  AddNameSuccess = "Cet utilisateur doit ajouter son nom à son compte pour que d'autres puissent voir sa certification.",
  AlreadyClaimed = 'Il semble que vous ayez déjà revendiqué la certification',
  CertClaimSuccess = "Vous avez revendiqué avec succès la Certification ! Félicitations au nom de l'équipe !",
  CertificateMissing = "La certification que vous avez essayé de visualiser n'existe pas",
  CertsPrivate = 'vous avez choisi de rendre vos certifications privées. vous devrez rendre vos certifications publiques pour que les autres puissent les consulter.',
  CompleteProjectFirst = "Vous devez d'abord terminer le projet.",
  CreateTokenErr = "Une erreur s'est produite en essayant de créer un jeton",
  DeleteTokenErr = "Une erreur s'est produite en essayant de supprimer votre jeton",
  EmailValid = 'Votre adresse électronique a été modifiée avec succès, bon codage !',
  HonestFirst = "Pour demander une certification, vous devez d'abord accepter notre politique d'honnêteté académique.",
  IncompleteSteps = "Il semble que vous n'ayez pas accompli les étapes nécessaires. Veuillez compléter les projets requis pour prétendre à la certification. Certification.",
  LocalCodeSaved = 'Sauvegardé ! Votre code a été enregistré dans le stockage local de votre navigateur.',
  LocalCodeSaveError = "Oups, votre code n'a pas été enregistré, la mémoire locale de votre navigateur est peut-être pleine.",
  NameNeeded = "Nous avons besoin de votre nom pour pouvoir le mettre sur votre certification. Ajoutez votre nom aux paramètres de votre compte et cliquez sur le bouton d'enregistrement. Nous pourrons alors délivrer votre certification.",
  None = '',
  NotEligible = "Cet utilisateur n'est pas éligible pour le moment pour les certifications.",
  NotHonest = "vous n'avez pas encore accepté notre engagement d'honnêteté académique.",
  NotRight = "Quelque chose ne va pas. Un rapport a été généré et l'équipe a été informée.",
  ProfilePrivate = "vous avez choisi de rendre votre profil privé. vous devrez rendre votre profil public pour que d'autres puissent voir votre certification.",
  ProgressReset = 'Votre progression a été réinitialisée',
  ProvideUsername = "Vérifiez si vous avez fourni un nom d'utilisateur et un rapport.",
  ReallyWeird = "Quelque chose de vraiment bizarre s'est produit, si cela se reproduit, pensez à nous écrire.",
  ReportSent = "Un rapport a été envoyé à l'équipe avec votre e-mail en copie.",
  SigninSuccess = 'Succès ! Vous vous êtes connecté à votre compte. Bon codage !',
  TokenCreated = 'Vous avez créé avec succès un nouveau jeton.',
  TokenDeleted = 'Votre jeton a été supprimé.',
  UpdatedPreferences = 'Nous avons mis à jour vos préférences',
  UsernameNotFound = "Nous n'avons pas trouvé d'utilisateur avec le nom d'utilisateur renseigné.",
  UsernameTaken = "Le nom d'utilisateur est déjà associé à un autre compte",
  UsernameUpdated = "Nous avons mis à jour votre nom d'utilisateur",
  UsernameUsed = "Le nom d'utilisateur est déjà associé à ce compte",
  UserNotCertified = 'Il semble que vous ne soyez pas certifié.',
  WrongName = "Un problème est survenu lors de la vérification du nom, veuillez réessayer. Si vous continuez à recevoir cette erreur, vous pouvez nous envoyer un message pour obtenir de l'aide.",
  WrongUpdating = 'Un problème est survenu lors de la mise à jour de votre compte. Veuillez vérifier et réessayer',
  WentWrong = "Un problème est survenu lors du traitement de votre don. Votre carte n'a pas été débitée."
}
